function smooth_scroll(target_px, speed) {
    if(typeof target_px == undefined) {
        console.warn('Tried smooth scrolling, but no target height was given!');
        return false;
    }

    if(!speed) {
        speed = 500;
    }

    jQuery('html, body').animate({ scrollTop: target_px }, speed);
}

if(typeof Vue !== 'undefined' && document.getElementById("vue-vacatures")) {    
    var vue_vacatures = new Vue({
        el: '#vue-vacatures', 
        data: {
            prefill_multiple: vue_vacatures_prefill_multiple,
            prefill: vue_vacatures_prefill,
            filter: vue_vacatures_filter,
            checked: {
                type: [],
                functie: [], 
                locatie: [],
                sector: [],
                uren: [],
            },
            vacatures: [],
            loaded: false,
            paged: 1,
            pagination: '',
            is_loading: true,
            mobile_sidebar_visible: false
        },
        methods: {
            reset_form: function() {
                var self = this;

                for (var key in self.checked) {
                    self.checked[key] = [];
                }

                window.history.replaceState(null, null, '?');

                self.reset_paged();

                self.update_vacatures();   

                smooth_scroll((jQuery('#vue-vacatures').offset().top - 150), 350);
            },
            update_vacatures: function() {
                var self = this;

                self.is_loading = true;

                // Save the filter state to the URL parameters
                // Make a copy of the reactive checked property to enable making changes
                var params = JSON.parse(JSON.stringify(self.checked));

                // Clean empty values
                for (var propName in params) {
                    if (params[propName] === null || params[propName] === undefined) {
                    delete params[propName];
                    }
                    else if(Array.isArray(params[propName])) {
                        if(params[propName].length < 1) {
                            delete params[propName];
                        }
                    }
                }

                // Add paged to params if higher than 2
                // We choose not to use the word "paged" in the url, because WP changes this to /page/$ on reload
                if(self.paged > 1) {
                    params.vac_paged = self.paged;
                }

                if(Object.keys(params).length > 0) {
                    var esc = encodeURIComponent;
                    var query = '?'+Object.keys(params)
                        .map(function(k) {return esc(k) + '=' + esc(params[k]);})
                        .join('&');

                    // Push the new params to the URL
                    window.history.replaceState(null, null, query);
                }

                // Start POST
                self.ajax = jQuery.ajax({
                    type: "POST",
                    url: cpx_ajax_url,
                    data: {
                        action: 'vacatures_callback',
                        checked: self.checked,
                        paged: self.paged
                    },
                    success: function(response) {
                        response = JSON.parse(response);
                        if(response.vacatures[0] && response.vacatures[0].id.length != 0) {
                            self.vacatures = response.vacatures;
                            self.pagination = response.pagination;
                        }
                        else {
                            self.vacatures = [];
                            self.pagination = '';
                        }

                        self.is_loading = false;
                        self.loaded = true;
                    },
                    error: function(error){
                        console.log(error);
                    }
                });
                // END POST
            },
            change_paged: function(new_paged) {
                var self = this;
                self.paged = new_paged;
                self.update_vacatures();

                // Smooth scroll to the top of this view
                smooth_scroll((jQuery('#vue-vacatures').offset().top - 150), 350);
            },
            reset_paged: function() {
                var self = this;
                self.paged = 1;
            }
        },
        watch: {
            mobile_sidebar_visible: function(val) {
                smooth_scroll((jQuery('#vue-vacatures').offset().top - 150), 350);
            }
        },
        mounted() {
            var self = this;
            // Check if user is returning from next page (history state backwards)
            var query_string = window.location.search;
            var url_params = new URLSearchParams(query_string);

            var locatie_str = url_params.get('locatie');
            if(locatie_str) {
                var locatie = locatie_str.split(',');
                self.checked.locatie = locatie;
            }

            var type_str = url_params.get('type');
            if(type_str) {
                var type = type_str.split(',');
                self.checked.type = type;
            }

            var sector_str = url_params.get('sector');
            if(sector_str) {
                var sector = sector_str.split(',');
                self.checked.sector = sector;
            }

            var functie_str = url_params.get('functie');
            if(functie_str) {
                var functie = functie_str.split(',');
                self.checked.functie = functie;
            }
            
            var uren_str = url_params.get('uren');
            if(uren_str) {
                var uren = uren_str.split(',');
                self.checked.uren = uren;
            }

            // Special one: check if paged
            var paged = url_params.get('vac_paged');
            if(paged) {
                self.paged = paged;
            }

            // Prefill if not history
            // We use a check of 2 length to prevent failing when only a ? remains in the URL bar after clearing
            if(query_string.length < 2) {
                console.log('prefill');
                // Prefill a single tax (default)
                if(self.prefill_multiple == false) {
                    console.log('default prefill');
                    if(self.prefill.tax && self.prefill.value) {
                    self.checked[self.prefill.tax] = self.prefill.value;
                    }
                }

                // Prefill multiple taxes
                else if(self.prefill_multiple == true) {
                    console.log('multiple prefill');
                    for(i=0;i<self.prefill.length;i++){
                        var tax_mapping = {
                            'vacature_locatie': 'locatie',
                            'vacature_type': 'type',
                            'vacature_sector': 'sector',
                            'vacature_functie': 'functie',
                            'vacature_uren': 'uren',
                        };
                        self.checked[tax_mapping[self.prefill[i].taxonomy]].push(self.prefill[i].term_id);
                    }
                }
            }

            // Get the vacatures for the first time
            self.update_vacatures();
        }
    });
}